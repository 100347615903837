.hidden-middle {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    /* max-width: 100px; */ /* Adjust to the width you need */
    width: 1em;
    vertical-align: bottom;
}

.hidden-middle::before {
    content: "\2026";
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background: white;
    color: black;
    pointer-events: none; /* Allows text selection without obstruction */
}

.hidden-middle::selection {
    background: transparent; /* Make it invisible when selecting */
}

p::selection, span::selection {
    background: #b3d4fc; /* Standard selection color */
}

.for-search {
    /* max-width: 15rem;
    overflow: hidden; */
    overflow: visible;
    max-width: 100%;

    position: absolute;
    color: #0000 !important;
    padding: .625rem .7rem;
    left: 0;
    right: 0;
    top: 0;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
}