table.channels-table {
  /* width: 120%;
  td {
    width: 200px
  } */

  table-layout: fixed; /* Ensures equal-width columns */
  width: 100%; /* Table takes up full width */

    th, td {
      width: 150px;
      /* overflow: hidden; Prevents content overflow */
      /* text-overflow: ellipsis; Adds ellipsis for overflowing text */
  }
}